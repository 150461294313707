import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar, Style as StatusBarStyle } from "@capacitor/status-bar";
import { IonLoading, isPlatform } from "@ionic/react";
import { GlobalBanners } from "@src/app/routing/home/globalBanners";
import { useNotifyDeletionRequestCancelled } from "@src/appV2/Accounts/DeleteAccount/useNotifyDeletionRequestCancelled";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { getAnonymousLdUser, getSignedInLdUser } from "@src/appV2/FeatureFlags/cbhUserContext";
import { getAppInfo } from "@src/appV2/lib";
import { UXCamSdk } from "@src/appV2/lib/UXCamSdk/UXCamSdk";
import { useHyperTrack } from "@src/appV2/Tracking/HyperTrack";
import { useGetReferenceStatus } from "@src/hooks/useGetReferenceStatus";
import { logReferenceStatus } from "@src/utils/logReferenceStatus";
// Rule disabled since this is where we identify the current user to LaunchDarkly
// eslint-disable-next-line no-restricted-imports
import { useLDClient } from "launchdarkly-react-client-sdk";
import qs from "query-string";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { GlobalRouterPath } from "./constant/globalRoute";
import { HomeRoutes } from "./home";
import { AppRouterProps } from "./model";
import { PrivateRoute } from "./privateRoute";
import { PublicPage } from "./PublicPage";
import { NoUserPage } from "../404Pages";
import { useAnalyticsRouterTagging } from "../analyticsRouterTagging";
import { useAppUrlListener } from "../deepLink";
import { useDynamicLinkHandler } from "../dynamicLink";
import { initDatadogRum } from "../utils/datadog";
import { WelcomeRoute } from "../welcome/route";

export const AppRouter: FC<AppRouterProps> = ({
  initSession,
  isVerifying,
  isLoggingOut,
  authStateHasLoaded,
  agent,
  userId,
  ...props
}) => {
  const { isAuthorized } = props;
  const location = useLocation();
  const queryString = qs.parse(location.search);
  const dispatch = useDispatch();
  const ldFlags = useCbhFlags();
  const disableUxCamRecording = ldFlags?.[CbhFeatureFlag.DISABLE_UX_CAM];

  useEffect(() => {
    if (!isPlatform("capacitor")) {
      return;
    }

    StatusBar.setStyle({
      style: StatusBarStyle.Dark,
    });
  }, []);

  useEffect(() => {
    if (!authStateHasLoaded) {
      return;
    }
    setTimeout(() => {
      SplashScreen.hide();
    }, 200);
  }, [authStateHasLoaded]);

  const { status, isLoading: referenceLoading, variant } = useGetReferenceStatus();

  useEffect(() => {
    if (referenceLoading || !userId) {
      return;
    }
    logReferenceStatus(userId, status, variant);
  }, [referenceLoading, status, userId, variant]);

  useEffect(() => {
    initDatadogRum();
  }, []);

  useEffect(() => {
    if (disableUxCamRecording) {
      UXCamSdk.stopSessionAndUploadData();
    }
    /**
     * FIXME - We don't have LD details when we initialize UXCam
     * so stopping it here after its initialization
     * Refactor this to stop UXCam initialization based on FF value
     */
  }, [disableUxCamRecording]);

  useEffect(
    () => {
      initSession(location.pathname, queryString.email as string);
    },
    /**
     * FIXME - `initSession` probably should only execute once, however,
     * it depends on pathname and an email in the queryString.
     * We don't want to execute it for every path change, so, we are currently ignoring
     * rule-of-hooks here.
     * I believe we should refactor to not use pathname, but rather `window.location`
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initSession]
  );

  const ldClient = useLDClient();

  useEffect(() => {
    if (!ldClient) {
      return;
    }

    (async () => {
      const cbhAppInfo = await getAppInfo();

      const ldUser = !agent?.userId
        ? getAnonymousLdUser(cbhAppInfo)
        : getSignedInLdUser(cbhAppInfo, {
            userId: agent?.userId ?? "",
            name: agent?.name ?? "",
            email: agent?.email ?? "",
            stage: agent?.stage ?? "",
            msa: agent?.address?.metropolitanStatisticalArea ?? "",
            city: agent?.address?.city ?? "",
            state: agent?.address?.state ?? "",
            stateCode: agent?.address?.stateCode ?? "",
            countryCode: agent?.address?.countryCode ?? "",
            qualification: agent?.preference?.qualification ?? "",
          });

      ldClient.identify(ldUser);
    })();
    // The agent object gets updated without changing its reference, so we have to memo each field separately.
  }, [
    ldClient,
    agent?.userId,
    agent?.name,
    agent?.email,
    agent?.stage,
    agent?.address?.metropolitanStatisticalArea,
    agent?.address?.city,
    agent?.address?.state,
    agent?.address?.stateCode,
    agent?.address?.countryCode,
    agent?.preference?.qualification,
    dispatch,
  ]);

  useHyperTrack(userId);
  useDynamicLinkHandler();
  useAppUrlListener();
  useAnalyticsRouterTagging();
  useNotifyDeletionRequestCancelled();

  return (
    <>
      <div className="cbhAppV1">
        <GlobalBanners />
        <Switch>
          <Route exact path={GlobalRouterPath.NO_USER_PAGE} component={NoUserPage} />
          <Route exact path="/" render={() => <Redirect to={GlobalRouterPath.HOME} />} />
          <Route
            exact
            path={GlobalRouterPath.LINK}
            render={() => <Redirect to={GlobalRouterPath.HOME} />}
          />
        </Switch>
      </div>
      <PrivateRoute
        path={[GlobalRouterPath.HOME, GlobalRouterPath.APP_V2_HOME]}
        component={HomeRoutes}
        authStateHasLoaded={authStateHasLoaded}
        {...props}
      />
      <div className="cbhAppV1">
        <Route path={GlobalRouterPath.WELCOME}>
          <PublicPage authStateHasLoaded={authStateHasLoaded} isAuthorized={isAuthorized}>
            <WelcomeRoute />
          </PublicPage>
        </Route>

        <IonLoading isOpen={isVerifying} message="Logging in..." />
        <IonLoading isOpen={isLoggingOut} message="Logging out..." />
      </div>
    </>
  );
};
